/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        //logo prevent href click
       $(".navbar-toggle").click(function () { 
          if (Modernizr.mq("only screen and (max-width: 992px)")) {
            $(".navbar-brand").toggleClass("customclass");
          }
        });

        $(document).on('click', function(event) {
            $(".navbar-brand").removeClass("customclass");
        });

        //sidebar add x close
       $("#side-navbar-search").click(function () { 
          $("#side-navbar-search>span").toggleClass('fa-times');
          $("#side-navbar-search>span").toggleClass('fa-search');
        });

        // handle form defaults
        $( ".gform_wrapper" ).on( "focus", "input[type=text], textarea", function() {
          if( this.value === this.defaultValue ) {
            this.value = "";
          }
        }).on( "blur", "input[type=text], textarea", function() {
          if( !this.value.length ) {
            this.value = this.defaultValue;
          }
        });

        //// Property Search Form main website

        //// Add form input placeholder
        $('#uwpqsf_id #uwpqsf_id_key').attr('placeholder', 'Enter text to search for');           

        //// About Us Dropdown menu
        $("#menu-item-26 a").append("<b class='caret-right'></b>");
        $("#menu-item-26 a").click(function () { 
        $("#menu-item-26 .sub-menu").toggleClass("sub-visible");  
        $("#menu-item-158 .sub-menu").removeClass("sub-visible");
        }); 


        //About us menu
       $("#menu-item-26 a").click(function () { 
          if (Modernizr.mq("only screen and (max-width: 992px)")) {
              $(this).toggleClass("arrowtop");
              $("#menu-item-26 b").toggleClass("caret-right-none");
            //  $("#menu-item-158 a").removeClass("arrowtopprop");
          }
        });

        //Our Properties menu
       $("#menu-item-158 a").click(function () { 
          if (Modernizr.mq("only screen and (max-width: 992px)")) {
            $(this).toggleClass("arrowtopprop");
            $("#menu-item-158 b").toggleClass("caret-right-none");
         //   $("#menu-item-26 a").removeClass("arrowtop"); 
          }
        });

        //// Our Properties Dropdown menu
        $("#menu-item-158 a").append("<b class='caret-right'></b>");
        $("#menu-item-158 a").click(function () { 
        $("#menu-item-158 .sub-menu").toggleClass("sub-visible");  
        $("#menu-item-26 .sub-menu").removeClass("sub-visible");         
        });         

        //// Hide dropdown menu when click outside of
        $(document).on('click', function(event) {
          if (!$(event.target).closest('#menu-primary-navigation a').length) {
            // Hide the menus.
            $(".sub-menu").removeClass("sub-visible");   
          }
        });

        //// Back to top links
        // Hide link when the page loads. Set display:none on the CSS for this item then this line is not required
        $("#back-top").hide();
         
        // Show or hide button on scroll
        $(window).scroll(function(){
            if($(window).scrollTop() > 400){ //if you scroll past 400px from top then show button
                $("#back-top").fadeIn();
            } else { // else hide button
                $("#back-top").fadeOut();
            }
        });
         
        // Animate scroll to page top
        $("#back-top a").click(function(e){
            $("body,html").animate({scrollTop:0},300); // scroll to top
            e.preventDefault(); // prevent default click action
        }); 


        //// News Boxes Animate
        $(".latest-news").toggle(function () {
            $("#news-1").animate({left:'257px'}, {queue: false, duration: 500});
            $("#news-1").fadeIn(); 
            $("#news-2").animate({left:'557px'}, {queue: false, duration: 500});
            $("#news-2").fadeIn();
            $("#news-3").animate({left:'857px'}, {queue: false, duration: 500});
            $("#news-3").fadeIn();                          
        }, function () {
            $(".newswidgetcol").animate({left:'-20px'}, {queue: false, duration: 500});
            $(".newswidgetcol").fadeOut();            
        });


          // BALANCE HEIGHTS Portfolio items
         function equalHeights(group) {
          if ($(document).width() >= 320){  
            group.removeAttr('style');
            var tallest = 0;
            group.each(function() {
              var thisHeight = $(this).height();
              if(thisHeight > tallest) {
                tallest = thisHeight;
              }
            });
            group.height(tallest);
          } else {
            group.removeAttr('style');
          }
        }

        function balanceHeights() {
          $("#page-content-cc").each(function() {
             equalHeights($(".portfoliobox", this));
          });
        }

        function balanceHeightssocial() {
          $("#page-content-cc").each(function() {
             equalHeights($(".socialbox", this));
          });
        }        

         $(window).load( function(){
          balanceHeights(); 
          balanceHeightssocial();
          });

         $(window).resize(function() {        
          balanceHeights(); 
          balanceHeightssocial();                        
          });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        //// homepage Slider
        $('.slider-for').slick({ //content
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplaySpeed: 7500,
          arrows: true,
          infinite: true,
          swipe: true,
          touchMove: true,
          focusOnSelect: true,
          autoplay: true
        });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },


    // Our Portfolio single page
    'single_property': {
      init: function() {
        // JavaScript to be fired on the about us page

       //// Single property carousel

      }
    },


    // Our Portfolio.
    'our_portfolio': {
      init: function() {
 
        //// Portfolio carousel
        $('.slider-portfolio').slick({ //content
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          infinite: true,
          fade: false,
          autoplaySpeed: 7000,
          autoplay:true,
        }); 

      }
    },


    // New Developments.
    'new_developments': {
      init: function() {
 
        //// Portfolio carousel
        $('.slider-portfolio').slick({ //content
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          infinite: true,
          fade: false,
          autoplaySpeed: 7000,
          autoplay:true,
        }); 

      }
    }    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.